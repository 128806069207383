<template>
<main>
  <section class="my-5">
    CV YO
    {{wpObj}}
  </section>
</main>
</template>

<script>
import axios from 'axios'
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Om mig',
  components: {

  },  
  data() {
    return {
      wpObj: {},
      api: process.env.VUE_APP_API_MIG,
      errors: [],
    }
  },
  async mounted () {
    try {
      const response = await axios.get(this.api)
      this.wpObj = response.data[0]
      console.log(this.wpObj)
      // console.log(this.$route.params.slug)
    } catch (e) {
      this.errors.push(e)
    }
  }
}
</script>
