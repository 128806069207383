<template>
<main>
  <section class="my-5">
    <AppCasesFlex />
  </section>

</main>
</template>

<script>
// @ is an alias to /src
import AppCasesFlex from '@/components/AppCasesFlex.vue'

export default {
  name: 'Projekter',
  components: {
    AppCasesFlex
  }
}
</script>
