<template>
  <section
    class="
      mx-auto
      md:px-24
      overflow-hidden
      sm:p-2
      md:p-10
    "
  >
        <!-- bg-gradient-to-r
      from-green-50
      to-green-100 -->
    <div class="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8 px-6">
      <h2 class="mx-auto mb-5 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          Tag et kig på nogle udvalgte cases:
      </h2>
  </div>
      <div id="grid-container"
      class="
        h-full
        mx-auto
        px-6
      "
    >
        <!-- md:grid-rows-4 md:grid-cols-2
        grid grid-cols-1
        grid-auto-rows
        gap-6 -->
      <router-link 
      v-for="project in projects || []" :key="project.id" :to="'/projekter/' + project.slug" :aria-label="'Se projektet om ' + project.title.rendered"
      class="          
          bg-green-500
          rounded-2xl
          flex
          shadow-lg
          justify-center
          items-center
          text-white text-2xl
          font-extrabold
          transition
          duration-700
          ease-in-out
          transform
          hover:scale-105
          hover:shadow-xl"
      >
      <article>
      <p>
      {{project.title.rendered}}
      </p>
      </article>
      </router-link>
    </div>
  </section>
</template>



<script>
import axios from "axios";

export default {
  name: "AppCasesFlex",
  props: {},
  data() {
    return {
      projects: null,
      highlightedProjects: null,
      projectsArr: [],
      casesAPI: process.env.VUE_APP_CASES,
      errors: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(this.casesAPI);
      this.projects = response.data;
      this.highlightedProjects = this.projects.filter(project => project.acf.highlighted == true)
      console.log(typeof this.highlightedProjects);
    } catch (e) {
      this.errors.push(e);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#grid-container {
  display: grid;
  grid-template-columns: 1;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 2em;
}
#grid-container a {
  grid-column: span 2;
  grid-row: span 8;
  background-image: linear-gradient(57deg,#5ed1af,#19b3bd);
}
#grid-container a:nth-child(2n) {
  background-image: linear-gradient(57deg,#1cafb3,#2390c5);
}
#grid-container a:nth-child(3n) {
  background-image: linear-gradient(150deg, rgb(38, 163, 52) -35.74%, rgb(25, 186, 223) 64.26%, rgb(38, 163, 52) 164.26%);
}
@media (min-width: 768px) {
  #grid-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 3em;
  }
  #grid-container a:nth-child(3n) {
    grid-column: span 4;
    grid-row: span 8;
  }
}

@media (min-width: 1024px) {
  .laptop\:text-center { text-align: center }
}

#grid-container:nth-child(1n) {

}
</style>
