<template>
<main>
  <section class="my-5">
    CONTACT YO
  </section>
</main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Kontakt',
  components: {

  }
}
</script>
