<template>
<main class="my-5">
    <section>
        NOT FOUND hest
  </section>
</main>
</template>

<script>
import axios from 'axios';

// @ is an alias to /src



export default {
  name: 'NotFoundProject',
  components: {

  },
  data() {
      return {
        projects: [],
        casesAPI: process.env.VUE_APP_CASES,
        errors: [],
      }
  },
    async mounted () {
    try {
      const response = await axios.get(this.casesAPI)
      this.projects = response.data
      console.log(this.projects)
    } catch (e) {
      this.errors.push(e)
    }
  },
//   computed: {
//     selectedProject() {
//         return this.projects.find(project => project.slug == this.$route.params.slug)
//     }
//   }

}
</script>
<style>


</style>